import React, {lazy} from "react";
import {motion, AnimatePresence} from "framer-motion";
import Testimonials from "./Testimonials/Testimonials";
import Contact from "./Contact/Contact";
import HeroApp from "../../assets/HeroApp.png"
import DarkHeroApp from "../../assets/DarkHeroApp.png";
import { Helmet, HelmetData } from "react-helmet-async";
import Products from "./Products/Products";

const Hero = lazy(() => import('./Hero/Hero'))
const Features = lazy(() => import('./Features/Features'))

const helmetData = new HelmetData({});

function HomePage({data, settings}) {

    return (
        <>
            <Helmet helmetData={helmetData}>
                <title>Creovia: Where Educational Data Meets Innovation</title>
                <meta name="title" content="Creovia: Where Educational Data Meets Innovation"/>
                <meta name="description" content="Join leading organizations in utilizing Creovia for advanced educational data analytics. Our solutions make data more accessible, insightful, and impactful."/>
                <meta property="twitter:title" content="Creovia: Where Educational Data Meets Innovation"/>
                <meta property="twitter:description" content=""/>
                <meta property="twitter:image" content="./assets/images/OG.png"/>
                <meta property="twitter:url" content="https://creovia.io/"/>
                <meta property="twitter:card" content="summary_large_image"/>
                <meta property="og:site_name" content="Creovia"/>
                <meta property="og:description" content=""/>
                <meta property="og:url" content="https://creovia.io/"/>
                <meta property="og:image" content="./assets/images/OG.png"/>
                <meta property="og:type" content="website"/>
                <link rel="canonical" href="https://creovia.io/" />
            </Helmet>
        <AnimatePresence>
            {data &&
                <motion.div
                    className="overflow-hidden dark:text-light-primary">
                    {/*<Banner />*/}
                    <div className="h-fit flex flex-col justify-center items-center">
                        <section id={"hero-section"}
                                 className={`px-8 w-full justify-between overflow-hidden relative bg-light-primary dark:bg-[#0F172A] ${settings.theme === 'dark' ? '' : ''}`}>
                            <motion.div
                                className={'md:flex justify-center items-center'}
                            >
                                <motion.div
                                    initial={{opacity: 0, y: 20}}
                                    animate={{opacity: 1, y: 0}}
                                    transition={{delay: 0.5, type: "tween",}}
                                    className={"pt-32 sm:pt-40 pr-6 basis-1/3 lg:basis-1/3"}>
                                    <Hero data={data.hero}/>
                                </motion.div>
                                <motion.div
                                    className={`pointer-events-none bg-no-repeat bg-contain bottom-0 pt-6 md:pt-40 z-20 h-full w-full lg:w-[1050px]`}
                                    initial={{opacity: 0, y: 200}}
                                    animate={{opacity: 1, y: 0}}
                                    transition={{delay: 1, type: "spring", duration: 2}}
                                >
                                    <img alt={'application sample'}
                                         src={settings.theme === 'dark' ? DarkHeroApp : HeroApp} className={`object-cover w-full min-h-[400px] max-h-[700px] h-fit`} />
                                </motion.div>
                            </motion.div>
                        </section>
                        <section id={'features'} className="bg-light-secondary z-10 w-full py-24 px-8 flex flex-col items-center dark:bg-dark-quaternary">
                            <Features data={data.features_section}/>
                        </section>
                        <section id={'products'} className="bg-light-primary dark:bg-[#0F172A] z-10 w-full py-24 px-8 flex flex-col items-center text-center">
                            <Products data={data.product_section} theme={settings.theme}/>
                        </section>
                        <section id={'testimonials'} className="px-8 py-24 w-full mx-auto bg-light-secondary flex flex-col justify-center items-center z-30 dark:bg-dark-quaternary">
                            <Testimonials data={data.testimonials}/>
                        </section>
                        {/*<section id={'testimonials'}*/}
                        {/*         className="px-8 py-24 w-full mx-auto bg-light-primary flex flex-col justify-center  items-center dark:bg-dark-primary z-30">*/}
                        {/*    <Clients data={data.clients}/>*/}
                        {/*</section>*/}
                        <section id={'contact'} className="relative px-8 py-24 w-full mx-auto bg-light-secondary flex flex-col justify-center items-center dark:bg-dark-quaternary z-30 bg-gradient-to-b from-40% from-light-secondary to-40% to-[#2563EB]
                        dark:bg-gradient-to-b dark:from-40% dark:from-dark-quaternary dark:to-40% dark:to-[#60A5FA]">
                            <Contact/>
                        </section>
                    </div>

                </motion.div>
            }
        </AnimatePresence>
            </>
    );
}

export default HomePage;
