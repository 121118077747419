import React from "react";
import {motion} from "framer-motion";
import TestimonialsImage from "../../../assets/TestimonialsImage.png";
import DarkTestimonialsImage from "../../../assets/DarkTestimonialsImage.png";

const Testimonials = ({ data }) => {

    return (
        <div className="flex flex-col items-center gap-3">
            <motion.h6
                initial={{opacity: 0, y:20}}
                whileInView={{opacity: 1, y:0}}
                transition={{delay: 0.3, }}
                viewport={{
                    once: true
                }}
                className="text-medium text-creovia-lightblue">{data.title}</motion.h6>
            <motion.h2
                initial={{opacity: 0, y:20}}
                whileInView={{opacity: 1, y:0}}
                transition={{delay: 0.3, }}
                viewport={{
                    once: true
                }}
                className="text-[34px] font-semibold">{data.header}</motion.h2>
            <motion.h4
                initial={{opacity: 0, y:20}}
                whileInView={{opacity: 1, y:0}}
                transition={{delay: 0.3, }}
                viewport={{
                    once: true
                }}
                className="text-[20px]">{data.subheader}</motion.h4>
            <motion.div
                initial={{y:100, opacity:0}}
                whileInView={{opacity: 1, y:0}}
                transition={{delay: 0.3, duration: 0.5, type: "tween"}}
                viewport={{
                    once: true
                }}
                className="bg-light-primary dark:bg-[#0F172A] p-8 lg:p-14 lg:px-20 justify-center items-center flex flex-col sm:flex-row flex-wrap gap-12 sm:gap-32 rounded-2xl mt-8 w-full">
                {data.stats.map((stat, key) => {
                    return (
                        <div key={key} className="flex flex-col items-center w-fit">
                            <div className="text-[45px] sm:text-[60px] font-semibold dark:text-creovia-lightblue text-creovia-blue">{stat.value.toLocaleString("en-US")}+</div>
                            <div>{stat.name}</div>
                        </div>
                    );
                })}
            </motion.div>
            <motion.div
                className={`pointer-events-none bg-no-repeat bg-contain bottom-0 sm:pt-8 z-20 h-full w-full lg:w-[1050px]`}
                initial={{opacity: 0, y: 100}}
                whileInView={{opacity: 1, y: 0}}
                transition={{delay: 0.5, type: "spring", duration: 2}}
                viewport={{
                    once: true
                }}
            >
                <img alt={'application sample'} src={localStorage.getItem('theme') === 'dark' ? DarkTestimonialsImage : TestimonialsImage} className={`object-cover w-full min-h-[400px] max-h-[700px] h-fit`} />
            </motion.div>
        </div>
    )
}

export default Testimonials;